<template>
    <div class="container-pc">
        <div class="login-view Global-W">
            <!-- <div class="login-title"></div> -->
            <!-- <div class="login-h"></div> -->

            <div class="login-box">
                <div class="login-left">
                    <div class="login-right-title FROM">
                        Create a member account
                    </div>
                    <div class="login-right-dec"> You can keep track of your online orders and get access to exclusive
                        services through your account </div>

                    <div class="login-from">

                        <div class="login-input-item">
                            <div>*{{ $t('Login.Email') }}</div>
                            <el-input v-model="registerData.username" class="login-input" clearable />
                        </div>

                        <div class="login-input-item">
                            <div>*Code</div>
                            <div class="login-input-item-view">
                                <el-input v-model="registerData.imgcode" class="login-input" clearable
                                    placeholder="Please enter the graphic verification code" />
                                <div class="login-code CURSOR" @click="reshCode">
                                    <img :src="imgCodeSrc" alt="">
                                </div>
                            </div>
                        </div>


                        <div class="login-input-item">
                            <div>*Email Code</div>
                            <div class="login-input-item-view">
                                <el-input v-model="registerData.smscode" class="login-input" clearable max-length="4" />
                                <div class="login-code CURSOR" @click="handleGetEmailCode">{{ codeValue }}</div>
                            </div>
                        </div>

                        <div class="login-input-item">
                            <div>Phone</div>
                            <el-input placeholder="Phone number" v-model="registerData.mobile" class="login-value"
                                clearable>
                                <template #prepend>
                                    <el-select v-model="countryItem" placeholder="Area code"
                                        @change="handleOnChangeArea">
                                        <el-option :label="countryItem.label" :value="countryItem.value"
                                            v-for="(countryItem, index) in countryData"></el-option>
                                    </el-select>
                                </template>
                            </el-input>
                        </div>

                        <div class="login-input-item">
                            <div>*{{ $t('Login.Password') }}</div>
                            <el-input v-model="registerData.password" type="password" class="login-input" clearable />
                        </div>

                        <div class="login-tips">
                            <el-checkbox v-model="vipCheckbox" fill="#414141" class="login-checkbox"></el-checkbox>
                            <span style="font-size: 12px">I have carefully read, understood and agreed to the ROSEONLY
                                membership policy</span>
                        </div>

                        <el-button :disabled="!vipCheckbox" class="login-sign" @click="handleRegister">Register
                            Account</el-button>

                    </div>
                </div>
                <div class="login-jiange"></div>
                <div class="login-right">
                    <div class="login-right-title FROM">Welcome back</div>
                    <div>Log in to your ROSEONLY account</div>
                    <el-button @click="handleGoPage" class="login-sign">Login</el-button>
                </div>
            </div>
        </div>
    </div>

    <div class="container-m">

        <div class="login-m-view">
            <div class="login-m-title f-w">Create a member account</div>
            <div class="login-m-tips f-w"> You can keep track of your online orders and get access to exclusive services
                through your account</div>
            <div class="login-m-tips f-w">

            </div>


            <div class="login-m-input-item">
                <div class="login-label">*Email</div>
                <input type="text" v-model="registerData.username" class="login-value" placeholder="Email">
            </div>

            <div class="login-m-input-item">
                <div class="login-label">*Code</div>
                <div class="login-label-view">
                    <input type="text" v-model="registerData.imgcode" class="login-value-code" placeholder="Code">
                    <div class="login-code" @click="reshCode">
                        <img :src="imgCodeSrc" alt="">
                    </div>
                </div>
            </div>
            <div class="login-m-input-item">
                <div class="login-label">*Email Code</div>
                <div class="login-label-view">
                    <input type="text" v-model="registerData.smscode" class="login-value-code" placeholder="Email Code">
                    <div class="login-code CURSOR" style="white-space: nowrap; overflow: hidden;"
                        @click="handleGetEmailCode">{{
                                codeValue }}
                    </div>
                </div>
            </div>

            <div class="login-m-input-item">
                <div class="login-label">Area code</div>
                <input type="text" v-model="countryItem" class="login-value" placeholder="Area code"
                    @click="handleShowPicker('isShowPicker')">
            </div>

            <div class="login-m-input-item">
                <div class="login-label">Phone number</div>
                <input type="text" v-model="registerData.mobile" class="login-value" placeholder="Phone number">
            </div>




            <div class="login-m-input-item">
                <div class="login-label">*password</div>
                <input type="password" v-model="registerData.password" class="login-value" placeholder="Password">
            </div>


            <div class="login-tips">
                <van-checkbox shape="square" icon-size="14px" checked-color="#414141"
                    v-model="vipCheckbox"></van-checkbox>
                <span style="margin-left: .15rem;">I have carefully read, understood and agreed to the ROSEONLY
                    membership policy</span>
            </div>
            <van-button class="login-button" :disabled="!vipCheckbox" block type="primary" color="#414141"
                native-type="submit" @click="handleRegister">Register Account</van-button>
        </div>

        <div class="login-m-register">
            <div class="login-m-title f-w">Log in to your ROSEONLY account</div>
            <div class="login-Create CURSOR" @click="handleGoPage">Login</div>
        </div>
    </div>

    <van-popup v-model:show="isShowPicker" round position="bottom" :style="{ height: '50%' }">
        <van-picker title="Area code" :columns="countryData" @confirm="handleOnConfirm" @cancel="handleOnCancel"
            :columns-field-names="{ text: 'label' }" />
    </van-popup>

    <van-popup v-model:show="isShowDatePicker" round position="bottom" :style="{ height: '50%' }">
        <van-date-picker v-model="currentDate" title="选择日期" :min-date="minDate" type="datetime"
            @click="handleOnConfirmDate" @cancel="handleOnCancel" />
    </van-popup>


</template>

<script setup name="Register">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import requestAsync from "../../utils/request";
import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus'
import config from '@/apis/config'
import countryData from "@/utils/countryData";

// 设置cookie
const router = useRouter()
const minDate = new Date(1900, 0, 1)
const countryItem = ref('')
const sixOptions = ref([{
    value: 'm',
    label: '男',
}, {
    value: 'n',
    label: '女',
}])

const currentDate = ref(['2024', new Date().getMonth() + 1, new Date().getDate()]);

const registerData = ref({
    username: '',
    password: '',
    mobile: '',
    loginBy: 'MAIL',
    prefix: '',
    imgcode: '',
    smscode: '',
    shopid: 'abc',
    sex: 'abc',
    birthday: 'abc',
})


const vipCheckbox = ref(false)

const isShowPicker = ref(false)
const isShowDatePicker = ref(false)

const imgCodeSrc = ref('')

onMounted(() => {
    reshCode()
})


const handleGoPage = () => {
    router.push({
        name: 'Login'
    })
}

const reshCode = () => {
    imgCodeSrc.value = `https://api.roseonly.com/user/getVerify?t=` + Math.random()
}

const handleShowPicker = (type) => {
    if (type == 'isShowPicker') {
        isShowPicker.value = !isShowPicker.value
    }
    if (type == 'isShowDatePicker') {
        isShowDatePicker.value = !isShowPicker.value
    }
}

const handleOnConfirm = (selectedValues) => {
    countryItem.value = selectedValues.selectedOptions[0].value
    registerData.value.mobile = ''
    isShowPicker.value = false
}

const handleOnConfirmDate = () => {
    registerData.value.date = currentDate.value[0] + '-' + currentDate.value[1] + '-' + currentDate.value[2]
    isShowDatePicker.value = false
}

const handleOnCancel = () => {
    isShowPicker.value = false
    isShowDatePicker.value = false
}

const handleRegister = async () => {
    if (!registerData.value.username ||
        !registerData.value.password ||
        !registerData.value.imgcode ||
        !registerData.value.smscode
    ) {
        return ElMessage.error('Please complete your information!')
    }
    if (!(registerData.value.mobile.includes('+'))) {
        if (countryItem.value) {
            registerData.value.mobile = '+' + countryItem.value + registerData.value.mobile
        }
    }


    const Register = await requestAsync({
        url: "/user/reg",
        data: registerData.value
    });
    console.log('Register', Register)

    if (Register.code == 200) {
        ElMessage({
            showClose: true,
            message: 'Registered successfully!',
            type: 'success',
        })
        Cookies.set('uid', Register.data.uid)
        Cookies.set('token', Register.data.token)
        router.push({
            name: 'Login'
        })
    } else if (Register.code == 500) {
        ElMessage({
            showClose: true,
            message: 'Verification code error',
            type: 'warning',
        })
    }
}

let codeValue = ref('Send the verification code')
const isEmailCode = ref(true)

const handleGetEmailCode = async () => {
    if (!isEmailCode.value) return
    isEmailCode.value = false
    if (!registerData.value.username) {
        return ElMessage.error('Please complete your information!')
    }
    if (!registerData.value.imgcode) {
        return ElMessage.error('Please enter the graphic verification code!')
    }
    const data = await requestAsync({
        url: "/user/sendMail",
        data: {
            mail: registerData.value.username,
            code: registerData.value.imgcode
        }
    });
    if (data.code == 200) {
        ElMessage({
            showClose: true,
            message: data.msg,
            type: 'success',
        })

        let _s = 60
        const timer = setInterval(() => {
            if (_s === 1) {
                clearInterval(timer)
                codeValue.value = 'Send the verification code'
                isEmailCode.value = true
            } else {
                _s--
                codeValue.value = `Valid within (${_s}) seconds`
            }
        }, 1000)



    } else {
        ElMessage.error(data.msg)
        isEmailCode.value = true
    }
    console.log('data', data)

}


const handleOnChangeArea = () => {
    registerData.value.mobile = ''
}

</script>

<style lang="scss" scoped>
:deep() {
    .el-button {
        background-color: $globalColor;
        color: #fff;
        font-weight: 700;
        border-color: $globalColor;
    }

    .el-button:hover {
        border-color: $globalColor;
    }

    .is-disabled {
        color: #fff;
        background-color: #bfbfbf;
        font-weight: 700;
        border-color: #bfbfbf;
    }

    .is-disabled:hover {
        color: #fff;
        background-color: #bfbfbf;
        font-weight: 700;
        border-color: #bfbfbf;
    }

    .el-input__wrapper {
        width: 100%;
        height: 50px;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        border-color: $globalColor !important;
        background-color: $globalColor !important;
    }

    .el-checkbox__inner:hover {
        border-color: $globalColor !important;
    }

    .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: $globalColor !important;
    }


}

.login-title {
    width: 100%;
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    color: #000;
    // border-bottom: 1px solid #d5d5d5;
}

.login-h {
    width: 100%;
    height: 1px;
    background-color: #d5d5d5;
    margin: 30px 0;
}

.login-box {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;

    .login-left {
        width: 35%;

        .login-input-item {
            width: 100%;
            height: 75px;
            margin-bottom: 10px;

            .login-input {
                height: 50px;
            }

            .login-input-item-view {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .login-code {
                    width: 300px;
                    height: 50px;
                    line-height: 50px;
                    margin-left: 20px;
                    font-size: 14px;
                }
            }
        }

        .login-right-dec {
            margin: 20px 0;
            font-size: 15px;
        }

        .login-tips {
            display: flex;
            align-items: center;
            width: 100%;
        }

        .login-checkbox {
            margin-right: 10px;
        }
    }

    .login-jiange {
        width: 1px;
        // height: 200px;
        background-color: #d5d5d5;
        margin: 0 100px;
    }

    .login-right-title {
        height: 35px;
        font-size: 20px;
        font-weight: 700;
        margin: 20px 0;
    }

    .login-right {
        width: 35%;



        .login-Forgot {
            display: inline-block;
            font-size: 13px;
            height: 25px;
            line-height: 25px;
            border-bottom: 1px solid #414141;
            margin: 5px 0;
        }

        .login-Forgot:hover {
            border-bottom: 1px solid $globalColor;
        }



        .login-vip {
            height: 60px;
            border-bottom: 1px solid #d5d5d5;
            font-size: 12px;
        }


    }

    .login-sign {
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin: 40px 0;
    }
}



.container-m {
    .login-m-view {
        width: 80%;
        margin: 0 auto;

        .login-m-title {
            width: 100%;
            height: .5rem;
            line-height: .5rem;
            text-align: center;
            font-size: .3rem;
        }

        .login-m-tips {
            font-size: .25rem;
            margin: .2rem 0;
            text-align: center;
        }

        .login-m-state {
            display: flex;
            align-items: center;
            width: 100%;
            height: 1rem;
            font-size: .3rem;

            div {
                margin-right: .2rem;
            }
        }

        .login-m-input-item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: .8rem;
            margin: .2rem auto;
            border-bottom: 1px solid #f1f1f1;

            .login-label {
                width: 2rem;
                line-height: .8rem;
                text-align: center;
                font-size: .25rem;
            }

            .login-label-view {
                flex: 1 1;
                display: flex;
                justify-content: space-between;

                .login-value-code {
                    width: 2rem;
                    font-size: .25rem;
                    padding-left: .3rem;
                    border: none;
                }

                .login-code {
                    width: 1.3rem;
                    height: .8rem;
                    line-height: .8rem;
                    font-size: .25rem;
                }
            }

            .login-value {
                flex: 1 1;
                font-size: .25rem;
                padding-left: .3rem;
                border: none;
            }
        }

        .login-tips {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: .25rem;
            margin: .3rem 0;
        }

    }

    .login-m-register {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 5rem;

        margin: 1rem 0;

        .login-m-title {
            width: 100%;
            height: .6rem;
            line-height: .5rem;
            text-align: center;
            font-size: .3rem;
        }



        .login-Create {
            display: inline-block;
            height: .25rem;
            line-height: .25rem;
            font-size: .3rem;
            font-weight: 700;
            margin: .25rem 0;
            border-bottom: 1px solid #414141;
        }
    }
}
</style>