const countryList = [
    { value: '61', label: 'Australia' },
    { value: '82', label: 'South Korea' },
    { value: '1', label: 'Canada' },
    { value: '60', label: 'Malaysia' },
    { value: '1', label: 'United States' },
    { value: '81', label: 'Japan' },
    { value: '65', label: 'Singapore' },
    { value: '44', label: 'UK' },
    { value: '86', label: 'China' },
    { value: '852', label: 'Hong Kong, China' },
    { value: '853', label: 'Macau, China' },
    { value: '886', label: 'Taiwan, China' },
    { value: '33', label: 'France' },
    { value: '49', label: 'Germany' },
    { value: '39', label: 'Italy' },
    { value: '3', label: 'Russia' },
]
export default countryList
